body {
  padding: 0 30px 30px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

span,
input,
button {
  margin-right: 5px;
}

.nav {
  display: flex;
  margin: 20px 0;
}

.nav {
  margin-right: 20px;
}

.nav a {
  margin-right: 20px;
}

li {
  margin-bottom: 10px;
  list-style: none;
}

h2 {
  display: block;
  font-size: 30px;
  font-weight: bold;
  margin: 20px 0;
}

p {
  margin: 0;
}

.container {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
}

.wrap {
  border: 3px solid black;
  padding: 20px;
}

.goal .wrap {
  border: 3px solid #f3920b;
  margin-bottom: 50px;
  min-height: 300px;
}

.goal h2 {
  color: #f3920b;
}

.border {
  margin-bottom: 5px !important;
}

.border_txt {
  opacity: 0.75;
}

.question_txt {
  font-size: 18px;
  margin: 0;
}

.answer_btn {
  margin-bottom: 10px;
}

.attention {
  color: red;
}